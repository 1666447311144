.headerDropdown {
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  z-index: 3;
  display: none;
  flex-direction: column;
  width: $tablet-breakpoint;
  border: 1px solid $border-color;
  padding: 20px;
  background-color: #ffffff;
  cursor: auto;
  box-shadow: 0px 1px 1px rgba(3, 7, 18, 0.08),
  0px 5px 4px rgba(3, 7, 18, 0.06),
  0px 12px 9px rgba(3, 7, 18, 0.05),
  0px 20px 15px rgba(3, 7, 18, 0.03),
  0px 32px 24px rgba(3, 7, 18, 0.02);

  &--visible {
    display: flex;
  }

  &-contacts {
    display: flex;
    width: 100%;
    column-gap: 20px;
    margin-bottom: 20px;

    &Item {
      display: flex;
      align-items: center;

      & > .icon {
        height: 24px;
        width: 24px;
        margin-right: 10px;

        & svg {
          height: 24px;
          width: 24px;
        }
      }
    }
  }

  &-links {
    display: flex;
    column-gap: 40px;

    & div {
      flex: 1;
      display: flex;
      flex-direction: column;

      & a,
      & span {
        margin-bottom: 15px;
        word-break: normal;
      }
    }
  }
}
