.main {

  & .homeSection {
    background-image: url("../../assets/images/homeSection/main_m.png");

    @media screen and (min-width: $tablet-breakpoint) {
      background-image: url("../../assets/images/homeSection/main.png");
    }
  }

  &-links {
    max-width: 1280px;
    margin: 0 auto;

    @media screen and (min-width: $tablet-breakpoint) {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  &-link {
    height: 400px;
    position: relative;
    color: #ffffff;

    @media screen and (min-width: $tablet-breakpoint) {
      flex: 1;
    }

    &--kuhni {
      background-image: url("../../assets/images/homeSection/kitchens.png");
      background-repeat: no-repeat;
      background-size: cover;
    }

    &--shkafy-kupe {
      background-color: rgba(0, 0, 0, 0.3);
      background-image: url("../../assets/images/homeSection/closets.png");
      background-repeat: no-repeat;
      background-size: cover;
    }

    &--garderobnye {
      background-color: rgba(0, 0, 0, 0.3);
      background-image: url("../../assets/images/homeSection/walk-in-closets.png");
      background-repeat: no-repeat;
      background-size: cover;
    }

    &--ofisnaya-mebel {
      background-image: url("../../assets/images/homeSection/office.png");
      background-repeat: no-repeat;
      background-size: cover;
    }

    &Container {
      height: 100%;
      width: 100%;
      padding-top: 60px;
      background-color: rgba(0, 0, 0, 0.3);
    }

    &Icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      margin: 0 auto 40px;
      background-color: $border-color;

      & img {
        width: 60%;
      }
    }

    &Title {
      padding: 10px;
      text-align: center;
      font-size: 1rem;
      font-family: "Montserrat-800", sans-serif;
      background-color: #19aa1e;
    }

    &Description {
      padding-left: 20px;
      padding-top: 20px;
      font-size: 0.9rem;
      line-height: 2;
    }

    & a {
      position: absolute;
      bottom: 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      width: 100%;
      padding: 10px 0;
      color: #ffffff;

      & .icon {
        margin-left: 10px;
        transform: rotate(270deg);
      }

      & .icon,
      & .icon svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  &-etc {
    margin: 20px auto;
    font-size: 1rem;
    line-height: 2;
    text-align: center;
  }
}
