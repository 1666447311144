.header {
  height: $header-height;
  width: 100%;
  border-bottom: 1px solid $border-color;
  padding: 0 20px;

  @media screen and (min-width: $tablet-breakpoint) {
    height: $header-height-desktop;
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }

  &-logo {
    height: 42px;

    @media screen and (min-width: $tablet-breakpoint) {
      height: 78px;
    }
  }

  &-menuIcon {
    cursor: pointer;

    @media screen and (min-width: $tablet-breakpoint) {
      display: none;
    }

    & > .icon,
    & > .icon svg {
      height: 32px;
      width: 32px;
    }
  }

  &-dropdown {
    position: relative;
    display: none;
    cursor: pointer;

    @media screen and (min-width: $tablet-breakpoint) {
      display: flex;
      align-items: center;
    }

    &Wrap {
      display: none;

      @media screen and (min-width: $tablet-breakpoint) {
        display: block;
      }
    }

    &Text {
      margin-right: 10px;
      font-size: 1.25rem;
      font-family: "Montserrat-500", sans-serif;
    }

    & > .icon,
    & > .icon svg {
      height: 38px;
      width: 38px;
    }
  }
}
