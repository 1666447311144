.walkInClosets {

  & .homeSection {
    background-image: url("../../assets/images/homeSection/walk-in-closets_m.png");

    @media screen and (min-width: $tablet-breakpoint) {
      background-image: url("../../assets/images/homeSection/walk-in-closets.png");
    }
  }
}
