// Common styles for pages
.page {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-cases {
    max-width: 992px;
    margin: 0 auto;

    @media screen and (min-width: $tablet-breakpoint) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      row-gap: 10px;
      column-gap: 10px;
    }
  }

  &-reviews {
    display: none;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;

    @media screen and (min-width: $tablet-breakpoint) {
      display: flex;

      & .review {
        display: block;
        flex: 1;
        width: 350px;
        min-width: 350px;
        max-width: 350px;
      }
    }

    &M {
      @media screen and (min-width: $tablet-breakpoint) {
        display: none;
      }
    }
  }
}
